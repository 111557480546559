import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import LoginView from '../views/LoginView.vue'
import UserView from '../views/UserView.vue'
import AccountView from '../views/AccountView.vue'
import TemplateView from '../views/TemplateView.vue'


Vue.use(VueRouter)
Vue.use(VueAxios, axios)

const routes = [
  {
    path: '/login',
    name: '登入',
    component: LoginView
  },
  {
    path: '/user',
    name: '使用者',
    component: UserView
  },
  {
    path: '/account',
    name: '帳戶名冊',
    component: AccountView
  },
  {
    path: '/template',
    name: '簡訊模版',
    component: TemplateView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
