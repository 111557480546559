<template>
  <div class="my-3">
    <b-row class="my-3">
      <b-col>
        <input
          ref="sms-excel-upload"
          type="file"
          class="form-control mb-2"
          @change="excelChange"
          disabled
        >
        <div class="form-text">僅支援 Excel 檔案</div>

        <b-button variant="outline-primary" @click="excelUpload" :disabled="!form.file">上傳</b-button>        
      </b-col>
    </b-row>

    <b-table
      :items="accounts"
      :fields="fields"
      label-sort-asc=""
      label-sort-desc=""
      label-sort-clear=""
      striped
      hover
      small
      class="table-light"
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(functions)="data">
        <b-button
          variant="outline-danger"
          class="my-3"
          size="sm"
          @click="deleteAccount(data.item)"
        >刪除</b-button>
      </template>
    </b-table>    
  </div>
</template>

<script>
export default {
  data () {
    return {
      accounts: [],
      form: {
        file: null
      },
      fields: [
        { key: 'index', label: '#' },
        { key: 'name', label: '姓名', sortable: true },
        { key: 'phone', label: '手機', sortable: true },
        { key: 'users_name', label: '產生者', sortable: true },
        { key: 'created_at', label: '產生時間', sortable: true },
        { key: 'updated_at', label: '更新時間', sortable: true },
        { key: 'functions', label: '功能' },
      ],
    }
  },
  methods: {
    excelChange () {
      this.form.file = this.$refs['sms-excel-upload'].files[0];
    },
    excelUpload () {
      let formData = new FormData();
      let conf = {
        headers: {
          mimeType: 'multipart/form-data'
        }
      };

      formData.append('file', this.form.file);
      formData.append('created_user', this.$store.state.user.id);
      
      this.axios
        .post('/sms/excel-account-upload', formData, conf)
        .then(() => {
          this.getAccount();
        })
        .catch(error => {
          console.log(error);
        });
    },
    getAccount () {
      this.axios
        .get('/sms/account')
        .then(response => {
          this.accounts = response.data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    deleteAccount (item) {
      if (confirm('確定刪除！')) {
        this.axios
          .delete(`/sms/account/${item.id}`)
          .then(() => {
            const index = this.accounts.findIndex(element => element.id == item.id);
            this.accounts.splice(index, 1);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
  },
  created () {
    this.getAccount();
  }
}
</script>

<style scoped>
.table >>> td {
  vertical-align: middle;
}
</style>