import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL="https://api.footmark.com.tw/api";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

if (!store.state.token) {
  store.commit('setToken', localStorage.getItem('token'));
  store.commit('setUser', JSON.parse(localStorage.getItem('user')));
}

// https://stackoverflow.com/questions/43051291/attach-authorization-header-for-all-axios-requests#answer-43052288
axios.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${ store.state.token }`;
  return config;
});

router.beforeEach((to, from, next) => {
  const token = store.state.token;

  if (to.path !== '/login' && !token) {
    next({ path: '/login' });
  } else if (to.path === from.path) {
    next({ path: from.path });
} else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
