import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    user: null
  },
  getters: {
    getToken (state) {
      return state.token;
    },
    getUser (state) {
      return state.user;
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token;
    },
    setUser (state, user) {
      state.user = user;
    },
  },
  actions: {
  },
  modules: {
  }
})
