<template>
  <div>
    <b-row>
      <b-col class="my-3">
        <b-form-group
          label="名稱"
          class="float-start me-3"
        >
          <b-form-input
            v-model="form.name"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="內容"
          class="float-start me-3"
        >
          <b-form-textarea
            v-model="form.content"
            size="sm"
          ></b-form-textarea>
        </b-form-group>

        <b-button
          variant="outline-primary"
          class="my-3 position-relative"
          style="top: 15px;"
          :disabled="!(Boolean(form.name) && Boolean(form.content))"
          @click="addTemplate()"
        >新增</b-button>
      </b-col>
    </b-row>

    <b-table
      :items="templates"
      :fields="fields"
      label-sort-asc=""
      label-sort-desc=""
      label-sort-clear=""
      striped
      hover
      small
      class="table-light"
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(functions)="data">
        <b-button
          variant="outline-danger"
          class="my-3"
          size="sm"
          @click="deletTemplate(data.item)"
        >刪除</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      templates: [],
      fields: [
        { key: 'index', label: '#' },
        { key: 'name', label: '名稱', sortable: true },
        { key: 'content', label: '內容', sortable: true },
        { key: 'users_name', label: '產生者', sortable: true },
        { key: 'created_at', label: '產生時間', sortable: true },
        { key: 'updated_at', label: '更新時間', sortable: true },
        { key: 'functions', label: '功能' },
      ],
      form: {
        name: null,
        content: null,
        created_user: null
      }
    }
  },
  methods: {
    deletTemplate (item) {
      if (confirm('確定刪除！')) {
        this.axios
          .delete(`/sms/template/${item.id}`)
          .then(() => {
            const index = this.templates.findIndex(element => element.id == item.id);
            this.templates.splice(index, 1);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    addTemplate () {
      if (confirm('確定新增！')) {
        this.axios
          .post('/sms/template', {
            name: this.form.name,
            content: this.form.content,
            created_user: this.$store.state.user.id,
          })
          .then((response) => {
            this.templates.push(response.data.data[0]);

            this.form.name = null;
            this.form.content = null;
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  },
  created () {
    this.axios
      .get('/sms/template')
      .then(response => {
        this.templates = response.data.data;
      })
      .catch(error => {
        console.log(error);
      })
  }
}
</script>

<style scoped>
.table >>> td {
  vertical-align: middle;
}
</style>
