<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <b-form-group
            label="Email"
            class="mb-2"
          >
            <b-form-input
              v-model="form.email"
              type="email"
              autocomplete="off"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="密碼"
            class="mb-4"
          >
            <b-form-input
              v-model="form.password"
              type="password"
              autocomplete="off"
              required
            ></b-form-input>
          </b-form-group>

          <div class="text-center">
            <b-button
              type="button"
              variant="outline-primary"
              @click="login()"
            >登入
            </b-button>  
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        email: null,
        password: null
      }
    }
  },
  methods: {
    login () {
      this.axios
        .post('/login', {
          email: this.form.email,
          password: this.form.password
        })
        .then((response) => {
          localStorage.setItem('token', response.data.authorisation.token);
          localStorage.setItem('user', JSON.stringify(response.data.user));

          this.$store.commit('setToken', response.data.authorisation.token);
          this.$store.commit('setUser', response.data.user);

          this.$router.push({ path: '/user' });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
}
</script>