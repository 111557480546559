<template>
  <div id="app">
    <b-container fluid>
      <b-row class="py-2 px-2">
        <b-col>
          <h1 class="text-start">簡訊群發系統</h1>
        </b-col>
        <b-col class="text-end">
          <div v-if="$store.state.user">
            <span>{{ $store.state.user.name }}</span>
            <b-button
              variant="link"
              @click="logout()"
            >登出</b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <hr v-if="$route.path == '/login'">

    <b-navbar v-else type="dark" variant="dark" class="px-3">
      <b-navbar-nav>
        <b-nav-item to="/user" :active="$route.path == '/user'">使用者</b-nav-item>
        <b-nav-item to="/account" :active="$route.path == '/account'">帳戶名冊</b-nav-item>
        <b-nav-item to="/template" :active="$route.path == '/template'">簡訊模版</b-nav-item>

        <!-- <b-nav-item-dropdown text="User" right>
          <b-dropdown-item href="#">Account</b-dropdown-item>
          <b-dropdown-item href="#">Settings</b-dropdown-item>
        </b-nav-item-dropdown> -->
      </b-navbar-nav>
    </b-navbar>

    <b-container fluid>
      <b-row>
        <b-col>
          
          <router-view/>

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  methods: {
    logout () {
      const config = {
        headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
      };

      this.axios
        .post('/logout', {
            email: this.$store.state.user,
          },
          config)
        .then((response) => {
          if (response.data.status === 'success') {
            localStorage.removeItem('token');
            localStorage.removeItem('user');

            this.$store.commit('setToken', null);
            this.$store.commit('setUser', []);
            
            this.$router.push({ path: '/login' });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
}
</script>
